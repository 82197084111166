import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AktuelleFejl from "../views/fejl/AktuelleFejl.vue";
import AfsluttedeFejl from "../views/fejl/AfsluttedeFejl.vue";
import AktuelleEftersyn from "../views/eftersyn/AktuelleEftersyn.vue";
import AfsluttedeEftersyn from "../views/eftersyn/AfsluttedeEftersyn.vue";
import KommendeEftersyn from "../views/eftersyn/KommendeEftersyn.vue";
import NewsFeed from "../views/NewsFeed.vue";
import Enheder from "../views/Enheder.vue";
import Lokation from "../views/Lokation.vue";
// import Notifikation from "../views/Notifikation.vue";
import Faq from "../views/Guide.vue";
import Login from "../views/Login.vue";
import Chart from "../views/Chart.vue";
import Notifikation from "../views/Notifikation.vue";
//import { Vue } from "vue-class-component";
import * as Vue from "vue";
import store from "@/store";

const routes = [
  {
    path: "/Notifikation",
    name: "Notifikation",
    component: Notifikation,
  },
  {
    path: "/chart",
    name: "Chart",
    component: Chart,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/aktuelle-fejl",
    name: "AktuelleFejl",
    component: AktuelleFejl,
  },
  {
    path: "/afsluttede-fejl",
    name: "AfsluttedeFejl",
    component: AfsluttedeFejl,
  },
  {
    path: "/aktuelle-eftersyn",
    name: "AktuelleEftersyn",
    component: AktuelleEftersyn,
  },
  {
    path: "/afsluttede-eftersyn",
    name: "AfsluttedeEftersyn",
    component: AfsluttedeEftersyn,
  },
  {
    path: "/enheder",
    name: "Enheder",
    component: Enheder,
  },
  {
    path: "/lokation",
    name: "Lokation",
    component: Lokation,
  },
  {
    path: "/guide",
    name: "Guide",
    component: Faq,
  },
  {
    path: "/kommende-eftersyn",
    name: "KommendeEftersyn",
    component: KommendeEftersyn,
  },
  {
    path: "/nyheder",
    name: "NewsFeed",
    component: NewsFeed,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //A Logged-in user can't go to login page again
  statePageChanged(to);
  if (to.meta.authRequired) {
    if (!hasAccess(to.name)) {
      router.push({
        name: "page-not-authorized",
      });
    }
  }

  return next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.name + " - Legislative CBRE | Intego";
  });
});

const hasAccess = () => {
  const userRole = localStorage.getItem("userRole");
  if (userRole) {
    return userRole === "Admin";
  } else {
    return false;
  }
};

/*
  Used when state must be reset, for example time intervals for tables.
*/
function statePageChanged(to) {
  store.dispatch("table/HANDLE_PAGE_CHANGE", to);
}

export default router;
