<template>
  <div class="page-top">
    <TransitionFade>
      <TableContainer
        v-if="issues"
        :table-data="issues"
        :table-type="'ISSUE'"
        :table-name="getDictionaryEntry('completedIssues.table.headerText')"
        :is-dashboard="false"
        :table-page-size="10"
      />
    </TransitionFade>
  </div>
</template>

<script>
import TableContainer from "../../components/TableContainer.vue";
import TransitionFade from "../../components/TransitionFade.vue";
import { createNamespacedHelpers } from "vuex";
const issues = createNamespacedHelpers("issue");
const location = createNamespacedHelpers("location");
const table = createNamespacedHelpers("table");

export default {
  name: "AfsluttedeFejl",
  components: {
    TableContainer,
    TransitionFade,
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    ...issues.mapGetters({ issues: "issuesCompleted" }),
    ...location.mapGetters(["activeLocation"]),
    ...table.mapGetters(["getTimeInterval"]),
  },
  created() {
    this.SET_ISSUE_STATUS("Completed");
    if (this.activeLocation) {
      this.GET_ISSUE_LIST();
      this.GET_ISSUE_THERMOGRAPHY();
    }
  },
  methods: {
    ...issues.mapActions(["GET_ISSUE_LIST", "GET_ISSUE_THERMOGRAPHY"]),
    ...issues.mapActions(["GET_ISSUE_LIST_LOCATIONS"]),
    ...issues.mapMutations(["SET_ISSUE_STATUS"]),
  },
  watch: {
    activeLocation: function () {
      this.GET_ISSUE_LIST();
      this.GET_ISSUE_THERMOGRAPHY();
    },
    getTimeInterval: function () {
      this.GET_ISSUE_LIST_LOCATIONS();
      this.GET_ISSUE_THERMOGRAPHY();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.page-top {
  @include tablet-portrait {
    margin-top: 3rem;
  }
}
</style>
