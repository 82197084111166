<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    stroke="currentColor"
    viewBox="0 0 15.6 19"
    class="className"
  >
    <g transform="translate(1 1)">
      <path
        d="M16.2,6h1.7a1.7,1.7,0,0,1,1.7,1.7V19.6a1.7,1.7,0,0,1-1.7,1.7H7.7A1.7,1.7,0,0,1,6,19.6V7.7A1.7,1.7,0,0,1,7.7,6H9.4"
        transform="translate(-6 -4.3)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M12.85,3h5.1a.85.85,0,0,1,.85.85v1.7a.85.85,0,0,1-.85.85h-5.1A.85.85,0,0,1,12,5.55V3.85A.85.85,0,0,1,12.85,3Z"
        transform="translate(-8.6 -3)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <circle r="7" cx="65%" cy="35%" stroke="none" :fill="fillColor" />
      <text
        :x="xValue"
        y="50%"
        :font-size="fontSize"
        font-weight="bold"
        fill="white"
        stroke="none"
      >
        {{ itemCount }}
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconControl",
  props: {
    className: {
      type: String,
      default: null,
    },
    itemCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      count: "",
      fontSize: 9,
      fillColor: "none",
      xValue: "35%",
    };
  },
  beforeMount() {
    if (this.itemCount > 0) {
      this.fillColor = "red";
      this.fontSize = 9;
      if (this.itemCount < 10) {
        this.xValue = "55%";
      }
    }
  },
};
</script>
